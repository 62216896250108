import $ from 'jquery';

$(function() {
  // スペック比較のからの戻りでスペックタブを選択する
  const parser = document.createElement('a');
  parser.href = window.location.href;
  const {
    hash
  } = parser;
  if ((hash === '#tab-alt-products') || (hash === '#tab-associations')) {
    $('.js-detail-tab a[href="' + hash + '"]').tab('show');
  }

  // 通常製品のみの場合は通常製品を初期表示する
  if ($('#js-ps-replace-product-alt-only').length) {
    $('.nav-tabs a[href="#tab-eco-product"]').tab('show');
  }

  if ($('#js-torikae-navi-form').length) {
    $('#js-torikae-navi-form').submit();
  }
});
