import $ from 'jquery';
import 'jstree/dist/jstree'
import 'jstree/dist/themes/default/style.css'
import 'jstree/dist/themes/default/throbber.gif'
import 'jstree/dist/themes/default/32px.png'
import 'jstree/dist/themes/default/40px.png'
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  $('#jstree-categories').jstree({
    core : {
      check_callback : true,
      worker: false,
      data : {
        url(node) {
          let { search } = window.location;
          if (!search) {
            const category_id = $('.js-select-top-category').val();
            search = `?category_form%5Btop_category_id%5D=${category_id}`;
          }
          return `categories/search.json${search}`;
        }
      }
    }
  });

  $('#jstree-categories').on('select_node.jstree', (e, data) => $.ajax({
    url: "/products",
    type: 'GET',
    data: {
      category_id: data.node.id
    },
    dataType: 'script',
    success(data) {},
    error(data) {}
  }));

  $('#js-category-expand-all').click(() => $('#jstree-categories').jstree('open_all'));
  $('#js-category-collapse-all').click(() => $('#jstree-categories').jstree('close_all'));

  $('.js-select-top-category').change(() => $('.js-category-form').submit());
});
