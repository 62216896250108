import $ from 'jquery'
import Cookies from 'js-cookie'

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if ($('.js-text-types').length > 0) {
    // リモコン品検索を選択されたら資料種類の選択を無効にする
    (function() {
      const textTypes = $('.js-text-types input');
      const changeDocumentTypesDisabled = function() {
        let disabled = false;
        for (let _ = 0; _ < textTypes.length; _++) {
          const textType = textTypes[_];
          if ((textType.value === 'remote_controller') && textType.checked) {
            disabled = true;
          }
        }
        const checkboxes = $('.js-document-types input');
        checkboxes.prop('disabled', disabled);
        if (disabled) { checkboxes.prop('checked', false); }
      };

      textTypes.on('change', changeDocumentTypesDisabled);
      changeDocumentTypesDisabled();
    })();
  }

  // 連打防止のために、ダウンロード処理中はボタンを無効化したい
  // send_dataの完了をJSで検知できないため、下記を参考に
  // cookieでダウンロード完了を検知できるようにしている
  // SEE: https://qiita.com/hidakatsuya/items/1b970d17ecb016dddec7
  $('#js-form-download-selected-bulk-search').submit(function(e) {
    let intervalId;
    $('#js-download-selected-bulk-search-button').prop('disabled', true);
    intervalId = setInterval((function() {
      if (Cookies.get('exported')) {
        $('#js-download-selected-bulk-search-button').prop('disabled', false);
        clearInterval(intervalId);
        Cookies.remove('exported');
      }
    }), 1000);
  });
});
