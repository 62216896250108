import $ from 'jquery';
import './simple-table-filter';

$(function() {
  $('.fancybox').fancybox();
  $('.table-sortable').tablesorter({
    theme: 'bootstrap',
    headerTemplate: '{content}{icon}',
    widgets: ['uitheme'],
    emptyTo: 'emptyMax',
    headers: {
      '.disabled': {
        sorter: false
      }
    }
  });

  $('.table-filterable').simpleTableFilter({filters: {
      3: '#js-document-filter',
      5: '#js-discontinued-filter',
    }
  });

  if ($('.js-table-with-checkbox').length > 0 || $('body.products.show, body.bulk_searches.show').length > 0) {
    // チェックボックスが1件でも選択されていたらボタンを有効化する関数
    const switchButtonStateByCheckboxes = function(buttonSelector, checkboxSelector) {
      const $tabPane = $('.tab-pane.active');
      const checkboxes = $tabPane.is('*') ?
        $tabPane.find(checkboxSelector)
        :
        $(checkboxSelector);
      const ids = [];
      for (let _ = 0; _ < checkboxes.length; _++) {
        const checkbox = checkboxes[_];
        if (checkbox.checked) { ids.push(checkbox.id); }
      }
      $(buttonSelector).toggleClass('disabled', ids.length < 1);
    };

    (function() {
      // 「表示されている全資料を選択」チェックボックスの制御
      $('.checkbox-select-all-tables').change(function() {
        $('.checkbox-select-all').prop('checked', this.checked);
        $('.checkbox-select-all').trigger('change');
      });

      // 一括ダウンロードボタンの制御
      const switchDownloadSelectedState = () => switchButtonStateByCheckboxes('.btn-download-selected', '.checkbox-download');

      // tablesorterのせいでチェックボックスがクリックしづらいので、セル全体をクリック可能にする
      $("th.tablesorter-header[data-column=0]").click(function() {
        const $currentCheckboxSelectAll = $(this).find('.checkbox-select-all');
        const newState = !$currentCheckboxSelectAll.prop('checked');
        $currentCheckboxSelectAll.prop('checked', newState);
        $currentCheckboxSelectAll.trigger('change');
      });

      // 一括選択チェックボックスの制御
      $('.checkbox-select-all').change(function() {
        const checboxes = $(this).closest('table').find('.checkbox-download');
        for (let i = 0; i < checboxes.length; i++) {
          const checkbox = checboxes[i];
          if (!$(checkbox).prop('disabled')) {
            $(checkbox).prop('checked', this.checked);
          }
        }
        switchDownloadSelectedState();
      });

      $('.checkbox-download').change(switchDownloadSelectedState);

      // 子チェックボックスが全て解除された場合は親チェックボックスを解除する
      $('input:checkbox').change(function() {
        const $closestTable = $(this).closest('table');
        if (!($closestTable.find('.checkbox-download:checked').length > 0)) {
          $closestTable.find('.checkbox-select-all').prop('checked', false);
        }
        if (!($('.checkbox-select-all:checked').length > 0)) {
          $('.checkbox-select-all-tables').prop('checked', false);
        }
      });

      // タブが変更されたときの動作
      $('a[data-toggle="tab"]').on('shown.bs.tab', switchDownloadSelectedState);
    })();

    // 製品比較ボタンの制御
    ((() => $('.checkbox-compare').change(() => switchButtonStateByCheckboxes('.btn-spec-compare', '.checkbox-compare'))))();
  }
});
