import $ from 'jquery';

// NOTE: 戻るリンク or ボタンでページを遷移した際に、チェックボックスやラジオボタンなどのinput要素の状態が復元されてから、jqueryの処理が走るようにする。
//       DOM読み込み完了を待つ $(function() {... だとinput要素の状態が復元される前にjqueryの処理が動いてしまうので、
//       ページのローディング完了を待つ $(window).on('load', function() {... を利用する。
$(window).on('load', function() {
  const updateMultiTorikaeSubmit = function() {
    const checkedValues = $('.js-irekae-check:checked:enabled, .js-up-check:checked:enabled').map((_, element) => $(element).val()).get()
    const checkedRcCount = checkedValues.filter((c) => c.endsWith('_remote_rc')).length
    const isCheckedRcCountGreaterThanOne = checkedRcCount > 1
    $('#js-multi-torikae-check-submit').prop('disabled', isCheckedRcCountGreaterThanOne)
    $('#js-alert-multiple-rc-selected').toggle(isCheckedRcCountGreaterThanOne)
  }

  const $productTypeRadioButtons = $('.js-multi-torikae-navi-product-type')
  if ($productTypeRadioButtons.length) {
    const toggleProductCheck = function($radioButton) {
      const target = $radioButton.val()
      if (target === 'irekae') {
        $('.js-irekae-check').prop('disabled', false)
        $('.js-up-check').prop('disabled', true)
      } else if (target == 'up') {
        $('.js-irekae-check').prop('disabled', true)
        $('.js-up-check').prop('disabled', false)
      }
    }

    const $checkedProductTypeRadioButton = $('.js-multi-torikae-navi-product-type:checked')
    toggleProductCheck($checkedProductTypeRadioButton)
    $productTypeRadioButtons.on('change', function() {
      toggleProductCheck($(this))
      updateMultiTorikaeSubmit()
    })
  }

  const $productCheckBoxes = $('.js-irekae-check, .js-up-check')
  if ($productCheckBoxes.length) {
    updateMultiTorikaeSubmit()

    $productCheckBoxes.on('change', function() {
      updateMultiTorikaeSubmit()
    })
  }

  const $currentUnitsSelect = $('#js-multi-torikae-navi-select-current-units')
  if ($currentUnitsSelect.length) {
    const updateReplacingUnitsSelect = function($currentUnitsSelect) {
      const selectedCurrentUnitsValue = $currentUnitsSelect.val()

      const $replacingUnitsSelect = $('#js-multi-torikae-navi-select-replacement-units')
      if ($replacingUnitsSelect.val() > selectedCurrentUnitsValue) {
        $replacingUnitsSelect.val(selectedCurrentUnitsValue)
      }

      $replacingUnitsSelect.children('option').each(function(_, element) {
        const showOrHide = Number($(element).val()) <= selectedCurrentUnitsValue
        $(element).toggle(showOrHide)
      })

      const $alertContainer = $('#js-alert-gas-water-heater-name-to-replace-all-if-current-units-7-or-above')
      if ($alertContainer.length) {
        const isShowAlert = selectedCurrentUnitsValue >= 7
        $alertContainer.toggle(isShowAlert)
        $('#js-select-replacement-units').toggle(!isShowAlert)
        $('#js-multi-torikae-navi-replace-all-hidden-field').prop('disabled', !isShowAlert)
      }

    }
    updateReplacingUnitsSelect($currentUnitsSelect)

    $currentUnitsSelect.on('change', (function() {
      updateReplacingUnitsSelect($(this))
    }))
  }

  if ($('.js-open-print-dialog').length) {
    window.print()
  }
})
