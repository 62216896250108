import $ from 'jquery';
import Cookies from 'js-cookie';

$(function() {
  $('.js-remove-announcement').on('click', (e) => {
    const key = 'techdocs-read-announcements';
    const value = Cookies.get(key)
    let ids = [];
    try {
      ids = JSON.parse(value);
    } catch {
      // ignore
    }
    const set = new Set(ids);

    const element = $(e.target);
    const id = element.data().id.toString();
    set.add(id);
    const newIds = JSON.stringify([...set]);
    Cookies.set(key, newIds, { expires: 365 });

    element.closest('.alert').fadeOut();
  })
});
