import $ from 'jquery'
import Cookies from 'js-cookie'

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const updatePdfContainerSticky = function() {
  if ($(window).width() >= 800) {
    $("#document-digital-parts__pdf-container").sticky({ topSpacing: 0, bottomSpacing: 118 });
  } else {
    $("#document-digital-parts__pdf-container").unstick();
  }
};

$(function() {
  ((() => // 部位切り替え
    $('#select-sections').change(function() {
      const jumpTag =  $('#select-sections').val();
      if (jumpTag !== '') {
        const pdfJumpTag = '#' + jumpTag;
        const pdfUrlData = $('#pdf-data-split').attr('data');
        const pdfUrlSrc = $('#pdf-data-split').attr('src');
        const pdfUrlDataWithTag = pdfUrlData.replace(/#.+/, pdfJumpTag);
        const pdfUrlSrcWithTag = pdfUrlSrc.replace(/#.+/, pdfJumpTag);
        $('#pdf-data-split').attr('data', pdfUrlDataWithTag);
        $('#pdf-data-split').attr('src', pdfUrlSrcWithTag);
        $('#pdf-data').attr('data', pdfUrlDataWithTag);
        $('#pdf-data').attr('src', pdfUrlSrcWithTag);
      }

      $.ajax({
        url: 'digital_part_sections',
        type: 'GET',
        data: {
          pdf_jump_tag: $('#select-sections').val(),
          section_name: $('#select-sections option:selected').text()
        },
        dataType: 'script',
        success(data) {},
        error(data) {}
      });
    })))();

  updatePdfContainerSticky();
  $(window).on('resize', () => updatePdfContainerSticky());

  // 連打防止のために、ダウンロード処理中はボタンを無効化したい
  // send_dataの完了をJSで検知できないため、下記を参考に
  // cookieでダウンロード完了を検知できるようにしている
  // SEE: https://qiita.com/hidakatsuya/items/1b970d17ecb016dddec7
  $('.js-form-download-parts-list-pdf').submit(function(e) {
    let intervalId;
    intervalId = setInterval((function() {
      if (Cookies.get('exported')) {
        $('.js-download-parts-list-pdf-button').prop('disabled', false).text('部品表付きPDFダウンロード');
        clearInterval(intervalId);
        Cookies.remove('exported');
      }
    }), 1000);
  });

  // ダウンロードエラー発生時にユーザーと開発者に通知する
  // https://n2jk.sonicgarden.jp/projects/26/messages/63795
  $('.js-digital-parts-pdf-download-form').on('ajax:error', function(event, xhr, status, error) {
    const errorMessage = `js-digital-parts-pdf-download-form failed. status: ${status} / error: ${error}`;
    window.bugsnagClient.notify(errorMessage);

    const message = 'ダウンロード中にエラーが発生しました。ページをリロードし、少し時間をおいてから再実行してください。';
    alert(message);
  });
});
